import useSWR from 'swr';
import { useMemo } from 'react';
// utils
import { fetcher, endpoints } from 'src/utils/axios';

import {
  getFirestore,
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  limit,
  orderBy,
  startAfter,
  where
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  getAuth,
} from 'firebase/auth';
// Log
import log from "loglevel";

import { getFunctions, httpsCallable } from "firebase/functions";



// ----------------------------------------------------------------------

export function useGetProducts() {
  const URL = endpoints.product.list;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      products: data?.products || [],
      productsLoading: isLoading,
      productsError: error,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !data?.products.length,
    }),
    [data?.products, error, isLoading, isValidating]
  );

  return memoizedValue;
}


const fetchCustomerProductDocuments = async () => {
  const custProducts = [];
  log.info("getProducts entered");
  log.info("getAuth", getAuth().currentUser.uid);

  const db = getFirestore();
  const q = query(collection(db, "customer-products"), where("userId", "==", getAuth().currentUser.uid));

  const querySnapshot = await getDocs(q);

  const castRef = doc(db, "cast", getAuth().currentUser.uid);
  const castSnapshot = await getDoc(castRef);

  const storage = getStorage(); // Move storage initialization outside the forEach loop

  await Promise.all(querySnapshot.docs.map(async (customerProductDoc) => {


    const data = customerProductDoc.data();
    const { id } = customerProductDoc;
    data.id = id;
    log.info("customerProductDoc.id", customerProductDoc.id);

    if (castSnapshot.exists()) {
      if (data.id === castSnapshot.data().productID) {
        data.isCasting = true;
      } else {
        data.isCasting = false;
      }

    } else {
      data.isCasting = false;
    }


    if (customerProductDoc.data().productThumbnailUrl) {
      const gsURL = customerProductDoc.data().productThumbnailUrl;
      const gsURLArray = gsURL.split("com/");
      const filePath = gsURLArray[1];

      const modelRef = ref(storage, filePath);
      const url = await getDownloadURL(modelRef);
      log.info("getdownloadurl", url);

      data.productThumbnailUrl = url; // Update the URL in the doc1.data() object
    }

    if (customerProductDoc.data().productGlbUrl) {
      const gsGLBURL = customerProductDoc.data().productGlbUrl;
      const gsGLBURLArray = gsGLBURL.split("com/");
      const glbFilePath = gsGLBURLArray[1];

      const glbRef = ref(storage, glbFilePath);
      const glbUrl = await getDownloadURL(glbRef);
      log.info("getdownloadurl", glbUrl);

      data.productGlbUrl = glbUrl; // Update the URL in the doc1.data() object
    }

    // Check if the 'images' property exists in the data object
    if (data.productImages && Array.isArray(data.productImages)) {
      // Loop through each image object in the 'images' array

      await Promise.all(data.productImages.map(async (image) => {
        // Check if the 'path' property is empty or undefined
        if (!image.path) {
          // Add the path property to the image object
          const gsURLImage = image.imageUrl;
          const gsURLArrayImage = gsURLImage.split("com/");
          const filePathImage = gsURLArrayImage[1];
          const modelRefImage = ref(storage, filePathImage);
          const urlImage = await getDownloadURL(modelRefImage);
          image.path = image.imageName;
          image.key = image.path;
          image.name = image.imageName;
          image.preview = urlImage;
        }
      }));
    }

    log.info(customerProductDoc.id, " => ", data);
    custProducts.push(data);

  }));

  log.info("fetchDocument products", custProducts);
  return { products: custProducts, isLoading: false, isValidating: false };

};


export function UseGetCustomerProducts() {

  return fetchCustomerProductDocuments().then((products, isLoading, isValidating) => {
    log.info("useGetCustomerProducts products", products);

    return {
      products: products || [],
      productsLoading: isLoading,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !products.length,
    };
  });
}



const fetchCollectionProductDocuments = async (collectionId) => {
  const collectionProducts = [];
  log.info("getProducts girdi");
  log.info("getAuth", getAuth().currentUser.uid);
  log.info("fetchCollectionProductDocuments : collectionId", collectionId)
  const db = getFirestore();
  const q = query(collection(db, "products"), where("userId", "==", getAuth().currentUser.uid), where("productCollectionId", "==", collectionId));

  const querySnapshot = await getDocs(q);

  const storage = getStorage(); // Move storage initialization outside the forEach loop

  await Promise.all(querySnapshot.docs.map(async (collectionProductDoc) => {

    const data = collectionProductDoc.data();
    data.id = collectionProductDoc.id;
    const gsURLThumbnail = collectionProductDoc.data().productThumbnailUrl;
    const gsURLArrayThumbnail = gsURLThumbnail.split("com/");
    const filePathThumbnail = gsURLArrayThumbnail[1];

    const modelThumbnailRef = ref(storage, filePathThumbnail);
    data.thumbnailUrl = await getDownloadURL(modelThumbnailRef);

    if (collectionProductDoc.data().imageUrl) {
      const gsURL = collectionProductDoc.data().imageUrl;
      const gsURLArray = gsURL.split("com/");
      const filePath = gsURLArray[1];

      const modelRef = ref(storage, filePath);
      const url = await getDownloadURL(modelRef);
      log.info("getdownloadurl", url);

      data.imageUrl = url; // Update the URL in the doc1.data() object
    }



    log.info("collectionProductDoc", " => ", data);
    collectionProducts.push(data);

  }));

  log.info("fetchDocument products", collectionProducts);
  return { products: collectionProducts, isLoading: false, isValidating: false };

};


export function UseGetCollectionProducts(collectionId) {
  log.info("UseGetCollectionProductscollectionId", collectionId)
  return fetchCollectionProductDocuments(collectionId).then((products, isLoading, isValidating) => {
    log.info("UseGetCollectionProducts products", products);

    return {
      products: products || [],
      productsLoading: isLoading,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !products.length,
    };
  });
}
const fetchProductCollectionDocuments = async () => {
  const custProductCollections = [];
  log.info("fetchProductCollectionDocuments girdi");
  log.info("getAuth", getAuth().currentUser.uid);

  const db = getFirestore();
  const q = query(collection(db, "productCollection"), where("userId", "==", getAuth().currentUser.uid));

  const querySnapshot = await getDocs(q);

  const storage = getStorage(); // Move storage initialization outside the forEach loop

  await Promise.all(querySnapshot.docs.map(async (customerProductCollectionDoc) => {

    const data = customerProductCollectionDoc.data();
    const { id } = customerProductCollectionDoc;

    data.id = id;

    if (customerProductCollectionDoc.data().imageUrl) {
      const gsURL = customerProductCollectionDoc.data().imageUrl;
      const gsURLArray = gsURL.split("com/");
      const filePath = gsURLArray[1];

      const modelRef = ref(storage, filePath);
      const url = await getDownloadURL(modelRef);
      log.info("getdownloadurl", url);

      data.imageUrl = url; // Update the URL in the doc1.data() object
    }

    log.info(customerProductCollectionDoc.id, " => ", data);
    custProductCollections.push(data);

  }));

  log.info("fetchDocument collections", custProductCollections);
  return { productCollections: custProductCollections, isLoading: false, isValidating: false };

};


export function UseGetProductCollectionDocuments() {

  return fetchProductCollectionDocuments().then((productCollections, isLoading, isValidating) => {
    log.info("useGetCustomerProducts products", productCollections);

    return {
      productCollections: productCollections || [],
      productCollectionsLoading: isLoading,
      productCollectionsValidating: isValidating,
      productCollectionsEmpty: !isLoading && !productCollections.length,
    };
  });
}
export function UseGetSharedWithMeProducts(sortBy) {

  return fetchSharedWithMeProductDocuments(sortBy).then((products, isLoading, isValidating) => {
    log.info("fetchSharedWithMeProductDocuments products", products);
    return {
      products: products || [],
      productsLoading: isLoading,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !products.length,
     
    };
  });
}



const fetchSharedWithMeProductDocuments = async (sortBy) => {
  const custProducts = [];
  log.info("getProducts entered");
  log.info("getAuth", getAuth().currentUser.uid);
  log.info("sortBy in func", sortBy)

  const functions = getFunctions();
  const sharedWithMeModels = httpsCallable(functions, 'getSharedWithMeModels');
  const result = await sharedWithMeModels({ sortOrder: sortBy === 'oldest' ? 'asc' : 'desc' });
  log.info("result",result)
  const storage = getStorage(); // Move storage initialization outside the forEach loop




  await Promise.all(result.data.map(async (customerProductDoc) => {

    const data = customerProductDoc;

    if (data.productGlbUrl) {
      const gsGLBURL = data.productGlbUrl;
      const gsGLBURLArray = gsGLBURL.split("com/");
      const glbFilePath = gsGLBURLArray[1];
  
      const glbRef = ref(storage, glbFilePath);
      const glbUrl = await getDownloadURL(glbRef);
      log.info("getdownloadurl", glbUrl);
  
      data.productGlbUrl = glbUrl; // Update the URL in the doc1.data() object
    }

    if (customerProductDoc.productThumbnailUrl) {
      const gsURL = customerProductDoc.productThumbnailUrl;
      const gsURLArray = gsURL.split("com/");
      const filePath = gsURLArray[1];

      const modelRef = ref(storage, filePath);
      const url = await getDownloadURL(modelRef);
      data.productThumbnailUrl = url; // Update the URL in the doc1.data() object
    }

    custProducts.push(data);

  }));

 
  return { products: custProducts,  isLoading: false, isValidating: false };

};

const fetchProductDocuments = async () => {
  const custProducts = [];
  log.info("getProducts entered");
  log.info("getAuth", getAuth().currentUser.uid);

  const db = getFirestore();
  const q = query(collection(db, "products"), where("userId", "==", getAuth().currentUser.uid));

  const querySnapshot = await getDocs(q);

  const storage = getStorage(); // Move storage initialization outside the forEach loop

  await Promise.all(querySnapshot.docs.map(async (customerProductDoc) => {

    const data = customerProductDoc.data();
    const { id } = customerProductDoc;
    data.id = id;

    if (customerProductDoc.data().productThumbnailUrl) {
      const gsURL = customerProductDoc.data().productThumbnailUrl;
      const gsURLArray = gsURL.split("com/");
      const filePath = gsURLArray[1];

      const modelRef = ref(storage, filePath);
      const url = await getDownloadURL(modelRef);
      log.info("getdownloadurl", url);

      data.productThumbnailUrl = url; // Update the URL in the doc1.data() object
    }

    // Check if the 'images' property exists in the data object
    if (data.productImages && Array.isArray(data.productImages)) {
      // Loop through each image object in the 'images' array

      await Promise.all(data.productImages.map(async (image) => {
        // Check if the 'path' property is empty or undefined
        if (!image.path) {
          // Add the path property to the image object
          const gsURLImage = image.imageUrl;
          const gsURLArrayImage = gsURLImage.split("com/");
          const filePathImage = gsURLArrayImage[1];
          const modelRefImage = ref(storage, filePathImage);
          const urlImage = await getDownloadURL(modelRefImage);
          image.path = image.imageName;
          image.key = image.path;
          image.name = image.imageName;
          image.preview = urlImage;
        }
      }));
    }

    log.info(customerProductDoc.id, " => ", data);
    custProducts.push(data);

  }));

  log.info("fetchDocument products", custProducts);
  return { products: custProducts, isLoading: false, isValidating: false };

};


export function UseGetProducts() {

  return fetchProductDocuments().then((products, isLoading, isValidating) => {
    log.info("useGetProducts products", products);

    return {
      products: products || [],
      productsLoading: isLoading,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !products.length,
    };
  });
}

const getLibraryShowcaseCount = async () => {

  log.info(" library getProducts entered");
  log.info("getAuth", getAuth().currentUser.uid);

  const db = getFirestore();
  const docRef = doc(db, "saved-products", getAuth().currentUser.uid);
  const docSnap = await getDoc(docRef);
  let libraryCountFromDb = 0
  if (docSnap.data() && docSnap.data().productIds) {
    libraryCountFromDb = docSnap.data().productIds.length
  }
  return { libraryCount: libraryCountFromDb };
}

export function UseGetLibraryShowcaseCount() {

  return getLibraryShowcaseCount().then((libraryCount) => {
    log.info("UseGetLibraryShowcaseCount", libraryCount);

    return libraryCount;

  });
}


const savedProductDocuments = async (itemCount) => {
  const custProducts = [];
  log.info(" library getProducts entered");
  log.info("getAuth", getAuth().currentUser.uid);

  const functions = getFunctions();
  const libraryModels = httpsCallable(functions, 'getLibraryModels');
  const result = await libraryModels({ lastDocumentId: itemCount });
  await Promise.all(result.data.map(async (savedProductDoc) => {

    const data = savedProductDoc;
    const { id } = savedProductDoc;
    data.id = id;

    if (savedProductDoc.productThumbnailUrl) {
      const storage = getStorage();
      const gsURL = savedProductDoc.productThumbnailUrl;
      const gsURLArray = gsURL.split("com/");
      const filePath = gsURLArray[1];

      const modelRef = ref(storage, filePath);
      const url = await getDownloadURL(modelRef);

      data.productThumbnailUrl = url; // Update the URL in the doc1.data() object
    }

    // Check if the 'images' property exists in the data object
    if (data.productImages && Array.isArray(data.productImages)) {
      // Loop through each image object in the 'images' array

      await Promise.all(data.productImages.map(async (image) => {
        // Check if the 'path' property is empty or undefined
        if (!image.path) {
          const storage = getStorage();
          // Add the path property to the image object
          const gsURLImage = image.imageUrl;
          const gsURLArrayImage = gsURLImage.split("com/");
          const filePathImage = gsURLArrayImage[1];
          const modelRefImage = ref(storage, filePathImage);
          const urlImage = await getDownloadURL(modelRefImage);
          image.path = image.imageName;
          image.key = image.path;
          image.name = image.imageName;
          image.preview = urlImage;
        }
      }));
    }
    custProducts.push(data);

  }));
  return { products: custProducts, isLoading: false, isValidating: false };

};
export function UsesavedProductDocuments(pageCount) {

  return savedProductDocuments(pageCount).then((products, isLoading, isValidating) => {
    log.info("UsesavedProductDocuments products", products);

    return {
      products: products || [],
      productsLoading: isLoading,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !products.length,

    };
  });
}


const publishShowcase = async (modelId,arPermissionState) => {
  const functions = getFunctions();
  const publishShowcaseFunction = httpsCallable(functions, 'publishShowcase');

  try {
    await publishShowcaseFunction({ model_id: modelId, arEnabled: arPermissionState });
    return { isLoading: false, isError: false };
  } catch (error) {
    console.error('Error publishing showcase:', error);

    // You can handle specific error codes here
    let errorMessage = 'An error occurred while publishing showcase.';
    if (error.code === 'unauthenticated') {
      errorMessage = 'User is not authenticated.';
    } else if (error.code === 'permission-denied') {
      errorMessage = 'Permission denied: This model does not belong to you.';
    } else if (error.code === 'invalid-action') {
      errorMessage = 'Invalid action.';
    } else {
      // Handle other errors
    }

    return { isLoading: false, isError: true, errorMessage };
  }
};

export function UsePublishShowcase(modelId,arPermissionState) {
  return publishShowcase(modelId,arPermissionState).then(({ isLoading, isError, errorMessage }) => {
    log.info("");
    return {
      publishShowcaseLoading: isLoading,
      publishShowcaseError: isError,
      publishShowcaseErrorMessage: errorMessage
    };
  });
}


const unpublishShowcase = async (modelId, arPermissionState) => {
  const functions = getFunctions();
  const unpublishShowcaseFunction = httpsCallable(functions, 'unpublishShowcase');
  log.info("arPermissionState unpublishShowcase", arPermissionState);

  try {
    await unpublishShowcaseFunction({ model_id: modelId, ar_enabled: arPermissionState  });
    return { isLoading: false, isError: false };
  } catch (error) {
    console.error('Error publishing showcase:', error);

    // You can handle specific error codes here
    let errorMessage = 'An error occurred while publishing showcase.';
    if (error.code === 'unauthenticated') {
      errorMessage = 'User is not authenticated.';
    } else if (error.code === 'permission-denied') {
      errorMessage = 'Permission denied: This model does not belong to you.';
    } else if (error.code === 'invalid-action') {
      errorMessage = 'Invalid action.';
    } else {
      // Handle other errors
    }

    return { isLoading: false, isError: true, errorMessage };
  }
};

export function UseUnpublishShowcase(modelId, arPermissionState) {
  return unpublishShowcase(modelId, arPermissionState).then(({ isLoading, isError, errorMessage }) => {
    log.info("");
    return {
      publishShowcaseLoading: isLoading,
      publishShowcaseError: isError,
      publishShowcaseErrorMessage: errorMessage
    };
  });
}



const updateUserLoginInfo = async () => {

  const functions = getFunctions();
  const callUpdateUserLoginInfo = httpsCallable(functions, 'updateUserLoginInfo');

  try {
    await callUpdateUserLoginInfo({applicationType:"Webapp"});
    console.log('Login info updated successfully');
  } catch (error) {
    console.error('Error updating login info:', error);
  }
};

export function UseUpdateUserLoginInfo() {
  updateUserLoginInfo();
}


const generateTDID = async (modelId) => {
  const functions = getFunctions();
  const generateTDIDFunction = httpsCallable(functions, 'generateTDID');

  try {
    const docSnap = await generateTDIDFunction({ productId: modelId });
    log.info("docSnap generateTDID", docSnap.data)

    return { tdId: docSnap.data.tdid, isLoading: false, isError: false };
  } catch (error) {
    console.error('Error publishing showcase:', error);

    // You can handle specific error codes here
    let errorMessage = 'An error occurred while publishing showcase.';
    if (error.code === 'unauthenticated') {
      errorMessage = 'User is not authenticated.';
    } else if (error.code === 'permission-denied') {
      errorMessage = 'Permission denied: This model does not belong to you.';
    } else if (error.code === 'invalid-action') {
      errorMessage = 'Invalid action.';
    } else {
      // Handle other errors
    }

    return { isLoading: false, isError: true, errorMessage };
  }
};

export function UseGenereateTDID(modelId) {
  return generateTDID(modelId).then(({ tdId, isLoading, isError, errorMessage }) => {
    log.info("");
    return {
      generetedId: tdId,
      generateTDIDLoading: isLoading,
      generateTDIDError: isError,
      generateTDIDErrorMessage: errorMessage
    };
  });
}


const fetchCustomerProductDocumentsNext = async (sortBy, lastProduct) => {
  const custProducts = [];
  log.info("getProducts entered");
  log.info("getAuth", getAuth().currentUser.uid);

  const db = getFirestore();
  const q = query(collection(db, "customer-products"), where("userId", "==", getAuth().currentUser.uid), orderBy("uploadDate", sortBy === "oldest" ? "asc" : "desc"),
    startAfter(lastProduct),
    limit(9));

  const querySnapshot = await getDocs(q);

  const storage = getStorage(); // Move storage initialization outside the forEach loop

  await Promise.all(querySnapshot.docs.map(async (customerProductDoc) => {

    const data = customerProductDoc.data();
    const { id } = customerProductDoc;
    data.id = id;

    if (customerProductDoc.data().productThumbnailUrl) {
      const gsURL = customerProductDoc.data().productThumbnailUrl;
      const gsURLArray = gsURL.split("com/");
      const filePath = gsURLArray[1];

      const modelRef = ref(storage, filePath);
      const url = await getDownloadURL(modelRef);
      log.info("getdownloadurl", url);

      data.productThumbnailUrl = url; // Update the URL in the doc1.data() object
    }

    // Check if the 'images' property exists in the data object
    if (data.productImages && Array.isArray(data.productImages)) {
      // Loop through each image object in the 'images' array

      await Promise.all(data.productImages.map(async (image) => {
        // Check if the 'path' property is empty or undefined
        if (!image.path) {
          // Add the path property to the image object
          const gsURLImage = image.imageUrl;
          const gsURLArrayImage = gsURLImage.split("com/");
          const filePathImage = gsURLArrayImage[1];
          const modelRefImage = ref(storage, filePathImage);
          const urlImage = await getDownloadURL(modelRefImage);
          image.path = image.imageName;
          image.key = image.path;
          image.name = image.imageName;
          image.preview = urlImage;
        }
      }));
    }

    log.info(customerProductDoc.id, " => ", data);
    custProducts.push(data);

  }));

  log.info("fetchDocument products", custProducts);
  return { products: custProducts, isLoading: false, isValidating: false };

};

export function UseGetCustomerProductDocumentsNext(lastProduct, sortBy) {

  return fetchCustomerProductDocumentsNext(lastProduct, sortBy).then((products, lastVisible, firstVisible, isLoading, isValidating) => {
    log.info("useGetShowcaserProducts products", products);

    return {
      products: products || [],
      productsLoading: isLoading,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !products.length,
      lastVisible: lastVisible || {},
      firstVisible: firstVisible || {},

    };
  });
}

const fetchSearchShowcaseProductDocuments = async (sortBy, searchKeyword) => {
  const custProducts = [];
  log.info("getProducts entered");
  log.info("getAuth", getAuth().currentUser.uid);
  log.info("sortBy in func", sortBy)

  const functions = getFunctions();
  const showcaseModels = httpsCallable(functions, 'searchShowcaseModels');
  const result = await showcaseModels({ search: searchKeyword, sortOrder: sortBy === 'oldest' ? 'asc' : 'desc' });
  log.info(result);

  const storage = getStorage(); // Move storage initialization outside the forEach loop

  await Promise.all(result.data.map(async (customerProductDoc) => {

    const data = customerProductDoc;

    if (customerProductDoc.productThumbnailUrl) {
      const gsURL = customerProductDoc.productThumbnailUrl;
      const gsURLArray = gsURL.split("com/");
      const filePath = gsURLArray[1];

      const modelRef = ref(storage, filePath);
      const url = await getDownloadURL(modelRef);
      log.info("getdownloadurl", url);

      data.productThumbnailUrl = url; // Update the URL in the doc1.data() object
    }

    // Check if the 'images' property exists in the data object
    if (data.productImages && Array.isArray(data.productImages)) {
      // Loop through each image object in the 'images' array

      await Promise.all(data.productImages.map(async (image) => {
        // Check if the 'path' property is empty or undefined
        if (!image.path) {
          // Add the path property to the image object
          const gsURLImage = image.imageUrl;
          const gsURLArrayImage = gsURLImage.split("com/");
          const filePathImage = gsURLArrayImage[1];
          const modelRefImage = ref(storage, filePathImage);
          const urlImage = await getDownloadURL(modelRefImage);
          image.path = image.imageName;
          image.key = image.path;
          image.name = image.imageName;
          image.preview = urlImage;
        }
      }));
    }

    log.info(customerProductDoc.id, " => ", data);
    custProducts.push(data);

  }));

  log.info("fetchDocument products", custProducts);
  return { products: custProducts, isLoading: false, isValidating: false };

};


export function UseGetShowcaseProducts(sortBy) {

  return fetchShowcaseProductDocuments(sortBy).then((products, lastVisible, isLoading, isValidating) => {
    log.info("useGetShowcaserProducts products", products);
    return {
      products: products || [],
      productsLoading: isLoading,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !products.length,
      lastVisible: lastVisible || {},
    };
  });
}



const fetchShowcaseProductDocuments = async (sortBy) => {
  const custProducts = [];
  log.info("getProducts entered");
  log.info("getAuth", getAuth().currentUser.uid);
  log.info("sortBy in func", sortBy)

  const functions = getFunctions();
  const showcaseModels = httpsCallable(functions, 'getShowcaseModels');
  const result = await showcaseModels({ sortOrder: sortBy === 'oldest' ? 'asc' : 'desc' });

  const storage = getStorage(); // Move storage initialization outside the forEach loop

  await Promise.all(result.data.map(async (customerProductDoc) => {

    const data = customerProductDoc;

    if (customerProductDoc.productThumbnailUrl) {
      const gsURL = customerProductDoc.productThumbnailUrl;
      const gsURLArray = gsURL.split("com/");
      const filePath = gsURLArray[1];

      const modelRef = ref(storage, filePath);
      const url = await getDownloadURL(modelRef);
      data.productThumbnailUrl = url; // Update the URL in the doc1.data() object
    }

    // Check if the 'images' property exists in the data object
    if (data.productImages && Array.isArray(data.productImages)) {
      // Loop through each image object in the 'images' array

      await Promise.all(data.productImages.map(async (image) => {
        // Check if the 'path' property is empty or undefined
        if (!image.path) {
          // Add the path property to the image object
          const gsURLImage = image.imageUrl;
          const gsURLArrayImage = gsURLImage.split("com/");
          const filePathImage = gsURLArrayImage[1];
          const modelRefImage = ref(storage, filePathImage);
          const urlImage = await getDownloadURL(modelRefImage);
          image.path = image.imageName;
          image.key = image.path;
          image.name = image.imageName;
          image.preview = urlImage;
        }
      }));
    }

    custProducts.push(data);

  }));

  const lv = result.data[result.data.length - 1].id
  log.info("lv", lv)
  return { products: custProducts, lastVisible: lv, isLoading: false, isValidating: false };

};

export function UseGetFeaturedProducts(sortBy) {

  return fetchFeaturedProductDocuments().then((products, isLoading, isValidating) => {
    log.info("UseGetFeaturedProducts products", products);
    return {
      products: products || [],
      productsLoading: isLoading,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !products.length,
     
    };
  });
}



const fetchFeaturedProductDocuments = async () => {
  const featuredProducts = [];
  log.info("fetchFeaturedProductDocuments ");
  log.info("getAuth", getAuth().currentUser.uid);

  const functions = getFunctions();
  const featuredModels = httpsCallable(functions, 'getFeaturedModels');
  const result = await featuredModels();

  const storage = getStorage(); // Move storage initialization outside the forEach loop

  await Promise.all(result.data.map(async (customerProductDoc) => {

    const data = customerProductDoc;

    if (customerProductDoc.productThumbnailUrl) {
      const gsURL = customerProductDoc.productThumbnailUrl;
      const gsURLArray = gsURL.split("com/");
      const filePath = gsURLArray[1];

      const modelRef = ref(storage, filePath);
      const url = await getDownloadURL(modelRef);
      data.productThumbnailUrl = url; // Update the URL in the doc1.data() object
    }

    // Check if the 'images' property exists in the data object
    if (data.productImages && Array.isArray(data.productImages)) {
      // Loop through each image object in the 'images' array

      await Promise.all(data.productImages.map(async (image) => {
        // Check if the 'path' property is empty or undefined
        if (!image.path) {
          // Add the path property to the image object
          const gsURLImage = image.imageUrl;
          const gsURLArrayImage = gsURLImage.split("com/");
          const filePathImage = gsURLArrayImage[1];
          const modelRefImage = ref(storage, filePathImage);
          const urlImage = await getDownloadURL(modelRefImage);
          image.path = image.imageName;
          image.key = image.path;
          image.name = image.imageName;
          image.preview = urlImage;
        }
      }));
    }

    featuredProducts.push(data);

  }));

 
 
  return { products: featuredProducts, isLoading: false, isValidating: false };

};
export function UseGetUserCollections(userId) {

  return fetchUserCollectionsDocuments(userId).then((collections, isLoading, isValidating) => {
    log.info("UseGetUserCollections collections", collections);
    return {
      collections: collections || [],
      productsLoading: isLoading,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !collections.length,
     
    };
  });
}



const fetchUserCollectionsDocuments = async (userId) => {
  const collectionsArray = [];
  log.info("fetchUserCollectionsDocuments ");


  const functions = getFunctions();
  const userCollections = httpsCallable(functions, 'getShowcaseCatalogsByUserId');
  const result = await userCollections({ creatorId: userId });

  const storage = getStorage(); // Move storage initialization outside the forEach loop

  await Promise.all(result.data.map(async (customerCollectionDoc) => {

    const data = customerCollectionDoc;

    if (customerCollectionDoc.imageUrl) {
      const gsURL = customerCollectionDoc.imageUrl;
      const gsURLArray = gsURL.split("com/");
      const filePath = gsURLArray[1];

      const modelRef = ref(storage, filePath);
      const url = await getDownloadURL(modelRef);
      data.httpURL = url; // Update the URL in the doc1.data() object
    }

    // Check if the 'images' property exists in the data object
    if (data.productImages && Array.isArray(data.productImages)) {
      // Loop through each image object in the 'images' array

      await Promise.all(data.productImages.map(async (image) => {
        // Check if the 'path' property is empty or undefined
        if (!image.path) {
          // Add the path property to the image object
          const gsURLImage = image.imageUrl;
          const gsURLArrayImage = gsURLImage.split("com/");
          const filePathImage = gsURLArrayImage[1];
          const modelRefImage = ref(storage, filePathImage);
          const urlImage = await getDownloadURL(modelRefImage);
          image.path = image.imageName;
          image.key = image.path;
          image.name = image.imageName;
          image.preview = urlImage;
        }
      }));
    }

    collectionsArray.push(data);

  }));

 
 
  return { collections: collectionsArray, isLoading: false, isValidating: false };

};


export function UsegetShowcasesByCatalogId(collectionId) {

  return getShowcasesByCatalogId(collectionId).then((products, isLoading, isValidating) => {
    log.info("getShowcasesByCatalogId products", products);
    return {
      products: products || [],
      productsLoading: isLoading,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !products.length,
     
    };
  });
}



const getShowcasesByCatalogId = async (collectionId) => {
  const productsArray = [];
  log.info("fetchUserCollectionsDocuments ");


  const functions = getFunctions();
  const catalogProducts = httpsCallable(functions, 'getShowcasesByCatalogId');
  const result = await catalogProducts({ catalogId: collectionId });

  const storage = getStorage(); // Move storage initialization outside the forEach loop

  await Promise.all(result.data.map(async (catalogProductsDoc) => {

    const data = catalogProductsDoc;

    if (catalogProductsDoc.productThumbnailUrl) {
      const gsURL = catalogProductsDoc.productThumbnailUrl;
      const gsURLArray = gsURL.split("com/");
      const filePath = gsURLArray[1];

      const modelRef = ref(storage, filePath);
      const url = await getDownloadURL(modelRef);
      data.httpURL = url; // Update the URL in the doc1.data() object
    }

    // Check if the 'images' property exists in the data object
    if (data.productImages && Array.isArray(data.productImages)) {
      // Loop through each image object in the 'images' array

      await Promise.all(data.productImages.map(async (image) => {
        // Check if the 'path' property is empty or undefined
        if (!image.path) {
          // Add the path property to the image object
          const gsURLImage = image.imageUrl;
          const gsURLArrayImage = gsURLImage.split("com/");
          const filePathImage = gsURLArrayImage[1];
          const modelRefImage = ref(storage, filePathImage);
          const urlImage = await getDownloadURL(modelRefImage);
          image.path = image.imageName;
          image.key = image.path;
          image.name = image.imageName;
          image.preview = urlImage;
        }
      }));
    }

    productsArray.push(data);

  }));

 
 
  return { products: productsArray, isLoading: false, isValidating: false };

};



export function UseSearchShowcaseProducts(sortBy, searchKeyword) {

  return fetchSearchShowcaseProductDocuments(sortBy, searchKeyword).then((products, isLoading, isValidating) => {
    log.info("useGetShowcaserProducts products", products);
    return {
      products: products || [],
      productsLoading: isLoading,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !products.length
    };
  });
}



const fetchShowcaseProductDocumentsNext = async (lastProduct, sortBy,) => {
  const custProducts = [];
  log.info("getProducts entered");
  log.info("getAuth", getAuth().currentUser.uid);

  const functions = getFunctions();
  const showcaseModels = httpsCallable(functions, 'getShowcaseModels');
  const result = await showcaseModels({ lastDocumentId: lastProduct, sortOrder: sortBy === 'oldest' ? 'asc' : 'desc' });

  const storage = getStorage(); // Move storage initialization outside the forEach loop

  await Promise.all(result.data.map(async (customerProductDoc) => {

    const data = customerProductDoc;

    if (customerProductDoc.productThumbnailUrl) {
      const gsURL = customerProductDoc.productThumbnailUrl;
      const gsURLArray = gsURL.split("com/");
      const filePath = gsURLArray[1];
      const modelRef = ref(storage, filePath);
      const url = await getDownloadURL(modelRef);

      data.productThumbnailUrl = url; // Update the URL in the doc1.data() object
    }

    // Check if the 'images' property exists in the data object
    if (data.productImages && Array.isArray(data.productImages)) {
      // Loop through each image object in the 'images' array

      await Promise.all(data.productImages.map(async (image) => {
        // Check if the 'path' property is empty or undefined
        if (!image.path) {
          // Add the path property to the image object
          const gsURLImage = image.imageUrl;
          const gsURLArrayImage = gsURLImage.split("com/");
          const filePathImage = gsURLArrayImage[1];
          const modelRefImage = ref(storage, filePathImage);
          const urlImage = await getDownloadURL(modelRefImage);
          image.path = image.imageName;
          image.key = image.path;
          image.name = image.imageName;
          image.preview = urlImage;
        }
      }));
    }
    custProducts.push(data);

  }));

  if (result.data.length > 1) {
    const lv = result.data[result.data.length - 1].id;
    log.info("lv", lv);
    return { products: custProducts, lastVisible: lv, firstVisible: null, isLoading: false, isValidating: false };
  }

  return { products: null, lastVisible: null, firstVisible: null, isLoading: false, isValidating: false };
};

export function UseGetShowcaseProductsNext(lastProduct, sortBy) {

  return fetchShowcaseProductDocumentsNext(lastProduct, sortBy).then((products, lastVisible, firstVisible, isLoading, isValidating) => {
    log.info("useGetShowcaserProducts products", products);

    return {
      products: products || [],
      productsLoading: isLoading,
      productsValidating: isValidating,
      productsEmpty: !isLoading && !products.length,
      lastVisible: lastVisible || {},
      firstVisible: firstVisible || {},

    };
  });
}

// ----------------------------------------------------------------------


const fetchCustomerProductDocument = async (id) => {

  let custProduct;
  const db = getFirestore();
  const storage = getStorage();
  const documentID = "customer-products"
  const docRef = doc(db, documentID, id);
  const docSnap = await getDoc(docRef);
  const imagesSrc = []

  if (docSnap.exists()) {
    log.info(" fetchCustomerProductDocument docSnap.data():", docSnap.data());
    // THUMBNAIL
    const gsURL1 = docSnap.data().productThumbnailUrl;
    const gsURLArray1 = gsURL1.split("com/");
    const filePath1 = gsURLArray1[1];

    const modelRef1 = ref(storage, filePath1);
    const url1 = await getDownloadURL(modelRef1);
    imagesSrc.push(url1);
    custProduct = docSnap.data();
    custProduct.id = docSnap.id
    log.info("Product", custProduct, id, documentID)
    if (custProduct.productImages) {
      await Promise.all(custProduct.productImages.map(async (image) => {

        const gsURLImage = image.imageUrl;
        const gsURLArrayImage = gsURLImage.split("com/");
        const filePathImage = gsURLArrayImage[1];
        const modelRefImage = ref(storage, filePathImage);
        const urlImage = await getDownloadURL(modelRefImage);
        image.path = image.imageName;
        image.key = image.path;
        image.name = image.imageName;
        image.preview = urlImage;

      }));
      log.info("fetchDocument product", custProduct);
      return { product: custProduct, isLoading: false };


    }

  } else {
    log.info("No such document!");
  }

  log.info("fetchDocument product", custProduct);
  return { product: custProduct, isLoading: false };

}


export function UseGetCustomerProduct(productId) {
  return fetchCustomerProductDocument(productId).then((product, isLoading) => {
    log.info("useGetCustomerProducts product", product);

    return {
      product: product || [],
      productsLoading: isLoading,
      productsEmpty: !isLoading && !product,
    };
  });
}
const fetchProductCollectionDocument = async (id) => {

  let productColl;
  const db = getFirestore();
  const storage = getStorage();
  const documentID = "productCollection"
  const docRef = doc(db, documentID, id);
  const docSnap = await getDoc(docRef);
  const imagesSrc = []

  if (docSnap.exists()) {
    log.info("fetchProductCollectionDocument-docSnap.data():", docSnap.data());
    // THUMBNAIL
    const gsURL1 = docSnap.data().imageUrl;
    const gsURLArray1 = gsURL1.split("com/");
    const filePath1 = gsURLArray1[1];

    const modelRef1 = ref(storage, filePath1);
    const url1 = await getDownloadURL(modelRef1);
    imagesSrc.push(url1);
    productColl = docSnap.data();
    productColl.id = docSnap.id
    log.info("Collection", productColl, id, documentID)


  } else {
    log.info("No such document!");
  }

  log.info("fetchDocument product", productColl);
  return { productCollection: productColl, isLoading: false };

}


export function UseGetProductCollection(collectionId) {
  log.info(" UseGetProductCollection collectionId", collectionId);
  return fetchProductCollectionDocument(collectionId).then((productCollection, isLoading) => {
    log.info("useGetCustomerProducts product", productCollection);

    return {
      productColl: productCollection || [],
      productCollectionLoading: isLoading,
      productCollectionEmpty: !isLoading && !productCollection,
    };
  });
}



const fetchShowcaseProductDocument = async (id) => {

  let custProduct;
  const db = getFirestore();
  const storage = getStorage();
  const documentID = "products"
  const docRef = doc(db, documentID, id);
  const docSnap = await getDoc(docRef);
  const imagesSrc = []

  if (docSnap.exists()) {
    log.info("fetchShowcaseProductDocument productCollectionId:", docSnap.data().productCollectionId);

    // THUMBNAIL
    const gsURL1 = docSnap.data().productThumbnailUrl;
    const gsURLArray1 = gsURL1.split("com/");
    const filePath1 = gsURLArray1[1];

    const modelRef1 = ref(storage, filePath1);
    const url1 = await getDownloadURL(modelRef1);
    imagesSrc.push(url1);
    custProduct = docSnap.data();
    custProduct.id = docSnap.id;
    if (custProduct.productImages) {
      await Promise.all(custProduct.productImages.map(async (image) => {

        const gsURLImage = image.imageUrl;
        const gsURLArrayImage = gsURLImage.split("com/");
        const filePathImage = gsURLArrayImage[1];
        const modelRefImage = ref(storage, filePathImage);
        const urlImage = await getDownloadURL(modelRefImage);
        image.path = image.imageName;
        image.key = image.path;
        image.name = image.imageName;
        image.preview = urlImage;

      }));
      return { product: custProduct, isLoading: false };
    }

  } else {
    log.info("No such document!");
  }
  return { product: custProduct, isLoading: false };

}


export function UseGetShowcaseProduct(productId) {
  return fetchShowcaseProductDocument(productId).then((product, isLoading) => {
    log.info("useGetCustomerProducts product", product);

    return {
      product: product || [],
      productsLoading: isLoading,
      productsEmpty: !isLoading && !product,
    };
  });
}



const fetchShowcaseProductExternalDocument = async (productId) => {

  let custProduct;
  const storage = getStorage();
  const functions = getFunctions();
  const getRecent = httpsCallable(functions, 'getRecentProduct');
  const docSnap = await getRecent({ id: productId });
  log.info("docSnapppp", docSnap.data)

  if (docSnap.data) {
    // THUMBNAIL
    const gsURL1 = docSnap.data.productThumbnailUrl;
    const gsURLArray1 = gsURL1.split("com/");
    const filePath1 = gsURLArray1[1];

    const modelRef1 = ref(storage, filePath1);
    const url1 = await getDownloadURL(modelRef1);
    custProduct = docSnap.data;
    custProduct.thumbnail = url1
    custProduct.id = docSnap.data.id;
    custProduct.tags = docSnap.data.tags;
    custProduct.modelType = docSnap.data.modelType;
    if (custProduct.productImages) {
      await Promise.all(custProduct.productImages.map(async (image) => {

        const gsURLImage = image.imageUrl;
        const gsURLArrayImage = gsURLImage.split("com/");
        const filePathImage = gsURLArrayImage[1];
        const modelRefImage = ref(storage, filePathImage);
        const urlImage = await getDownloadURL(modelRefImage);
        image.path = image.imageName;
        image.key = image.path;
        image.name = image.imageName;
        image.preview = urlImage;

      }));
      return { product: custProduct, isLoading: false };
    }

  } else {
    log.info("No such document!");
  }
  return { product: custProduct, isLoading: false };

}


export function UseGetSampleProductExternal(productId) {
  return fetchSampleProductExternalDocument(productId).then((product, isLoading) => {
    log.info("useGetCustomerProducts product", product);

    return {
      product: product || [],
      productsLoading: isLoading,
      productsEmpty: !isLoading && !product,
    };
  });
}
const fetchSampleProductExternalDocument = async (productId) => {

  let custProduct;
  const storage = getStorage();
  const functions = getFunctions();
  const getRecent = httpsCallable(functions, 'getSampleProduct');
  const docSnap = await getRecent({ id: productId });
  log.info("docSnap - fetchSharedProductExternalDocument", docSnap.data)

  if (docSnap.data) {
    // THUMBNAIL
    const gsURL1 = docSnap.data.productThumbnailUrl;
    const gsURLArray1 = gsURL1.split("com/");
    const filePath1 = gsURLArray1[1];

    const modelRef1 = ref(storage, filePath1);
    const url1 = await getDownloadURL(modelRef1);


    custProduct = docSnap.data;
    custProduct.thumbnail = url1
    custProduct.id = docSnap.data.id;
    custProduct.tags = docSnap.data.tags;
    custProduct.modelType = docSnap.data.modelType;

    if (custProduct.productGlbUrl) {
      const gsGLBURL = custProduct.productGlbUrl;
      const gsGLBURLArray = gsGLBURL.split("com/");
      const glbFilePath = gsGLBURLArray[1];

      const glbRef = ref(storage, glbFilePath);
      const glbUrl = await getDownloadURL(glbRef);
      log.info("getdownloadurl", glbUrl);

      custProduct.productGlbUrl = glbUrl; // Update the URL in the doc1.data() object
    }

    if (custProduct.productImages) {
      await Promise.all(custProduct.productImages.map(async (image) => {

        const gsURLImage = image.imageUrl;
        const gsURLArrayImage = gsURLImage.split("com/");
        const filePathImage = gsURLArrayImage[1];
        const modelRefImage = ref(storage, filePathImage);
        const urlImage = await getDownloadURL(modelRefImage);
        image.path = image.imageName;
        image.key = image.path;
        image.name = image.imageName;
        image.preview = urlImage;

      }));
      return { product: custProduct, isLoading: false };
    }

  } else {
    log.info("No such document!");
  }
  return { product: custProduct, isLoading: false };

}


export function UseGetShowcaseProductExternal(productId) {
  return fetchShowcaseProductExternalDocument(productId).then((product, isLoading) => {
    log.info("useGetCustomerProducts product", product);

    return {
      product: product || [],
      productsLoading: isLoading,
      productsEmpty: !isLoading && !product,
    };
  });
}


// async function UseGetSubscription() {

//   log.info("getSubscriptionConfig  enter");

//   const db = getFirestore();
//   const subscriptionsRef = doc(db, "subscriptions", getAuth().currentUser.uid);
//   const subscriptions = await getDoc(subscriptionsRef);
//   const subscriptionsData = subscriptions.data();

//   const subscriptionsConfigRef = doc(db, "subscription-config", subscriptionsData.subscriptionId);
//   const subscriptionConfig = await getDoc(subscriptionsConfigRef);
//   const subscriptionConfigData = subscriptionConfig.data()

//   const subDataJoined = { ...subscriptionConfigData, ...subscriptionsData }
//   log.info("subDataJoined", subDataJoined)

//   return subDataJoined;


// };


export async function UseGetSubscription() {
  const db = getFirestore();
  const subscriptionsRef = doc(db, "subscriptions", getAuth().currentUser.uid);
  // eslint-disable-next-line no-await-in-loop
  const subscriptions = await getDoc(subscriptionsRef);
  if (subscriptions.exists()) {
    return subscriptions.data();
  }
  throw new Error("Document not found even after retries.");
}



const proSubscribe = async (subscriptionParam) => {
  const functions = getFunctions();
  const createProSubscription = httpsCallable(functions, 'createProSubscription');
  const resultSub = await createProSubscription({ subscription: subscriptionParam });
  log.info("resultSub", resultSub);

  return { sub: resultSub.data, isLoading: false };;
}


export function UseProSubscribe(subscriptionParam) {
  return proSubscribe(subscriptionParam).then((sub, isLoading) => {
    log.info("proSubscribe sub", sub);

    return { subscription: sub, subLoading: isLoading };
  });
}



const openPortal = async () => {
  const functions = getFunctions();
  const customerPortal = httpsCallable(functions, 'customerPortalLink');
  const portalUrl = await customerPortal({});
  
  return { url: portalUrl.data, isLoading: false };;
}

export function UseOpenPortal(subscriptionParam) {
  return openPortal().then((url, isLoading) => {
    log.info("openPortal url", url);

    return { redUrl: url, loading: isLoading };
  });
}


const openCheckoutPortal = async (subscriptionParam) => {
  const functions = getFunctions();
  const customerPortal = httpsCallable(functions, 'createStripeSubscriptionSession');
  const portalUrl = await customerPortal({ subscriptionName: subscriptionParam });
  
  return { url: portalUrl.data, isLoading: false };;
}

export function UseOpenCheckoutPortal(subscriptionParam) {
  return openCheckoutPortal(subscriptionParam).then((url, isLoading) => {
    log.info("openPortal url", url);

    return { redUrl: url, loading: isLoading };
  });
}

const cancelSubscription = async () => {
  const functions = getFunctions();
  const cancelSub = httpsCallable(functions, 'cancelSubscription');
  const resultSub = await cancelSub();
  log.info("resultSub", resultSub);

  return { sub: resultSub.data, isLoading: false };;
}


export function UseCancelSubscription() {
  return cancelSubscription().then((sub, isLoading) => {
    log.info("cancelSubscription", sub);

    return { subscription: sub, subLoading: isLoading };
  });
}

// ----------------------------------------------------------------------


export function useGetProduct(productId) {
  const URL = productId ? [endpoints.product.details, { params: { productId } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      product: data?.product,
      productLoading: isLoading,
      productError: error,
      productValidating: isValidating,
    }),
    [data?.product, error, isLoading, isValidating]
  );

  return memoizedValue;
}


// ----------------------------------------------------------------------

export function useSearchProducts(inputQuery) {
  const URL = inputQuery ? [endpoints.product.search, { params: { inputQuery } }] : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.results || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
      searchEmpty: !isLoading && !data?.results.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}
const getUserProfile = async (uid, lastProduct) => {

  log.info("uid", uid , lastProduct);

  let userProfile;
  const storage = getStorage();
  const functions = getFunctions();
  const getUser = httpsCallable(functions, 'getUserProfile');
  const docSnap = await getUser({ userId: uid, lastDocumentId: lastProduct });
  log.info("getUserByDisplayName docSnap", docSnap.data)


  if (docSnap.data) {
    userProfile = docSnap.data;
    // THUMBNAIL
    if(docSnap.data.user.imageURL){
      const gsURL1 = docSnap.data.user.imageURL;
      const gsURLArray1 = gsURL1.split("com/");
      const filePath1 = gsURLArray1[1];
      const modelRef1 = ref(storage, filePath1);
      const url1 = await getDownloadURL(modelRef1);
      userProfile.user.imageURL = url1
    }

    if (docSnap.data.products && docSnap.data.products.length>0) {
      await Promise.all(docSnap.data.products.map(async (image) => {

        const gsURLImage = image.productThumbnailUrl;
        const gsURLArrayImage = gsURLImage.split("com/");
        const filePathImage = gsURLArrayImage[1];
        const modelRefImage = ref(storage, filePathImage);
        // const urlImage = await getDownloadURL(modelRefImage);
        image.httpURL = await getDownloadURL(modelRefImage);

      }));
      const lv = docSnap.data.products[docSnap.data.products.length - 1].id
      return { profile: userProfile,  lastVisible: lv, isLoading: false };
    }


  } else {
    log.info("No such document!");
  }
  return { profile: userProfile, isLoading: false };

}

export function UseGetUserProfile(uid, lastProduct) {
  return getUserProfile(uid, lastProduct).then((profile, lastVisible, isLoading) => {
    log.info("UseGetUserProfile profile", profile);

    return {
      profile: profile || [],
      lastVisible: lastVisible || {},
      profileLoading: isLoading,
      profileEmpty: !isLoading && !profile,
    };
  });
}